// NPM
import * as React from "react"
import { graphql } from 'gatsby'
import _ from 'lodash'

// Components
import Layout from "src/layouts/en"
import GenericProduct from "src/components/pages/GenericProduct/GenericProduct"

const GenericProductPageEn = ({ location, data }) => {
  const topNav = data.topNav
  const sections = _.get(data, `genericProductPage.sections`, [])
  const newsItems = _.get(data, `newsItems.nodes`, [])
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ data.genericProductPage }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <GenericProduct 
          sections={ sections } 
          newsItems={ newsItems } 
        />
    </Layout>
  )
}

export default GenericProductPageEn

export const query = graphql`
  query GenericProductPageEnQuery($id: String) {
    topNav: strapiTopNav(locale: {eq: "en"}) {
      ...topNavContent
    }
    
    genericProductPage: strapiPage(id: {eq: $id}) {
      ...pageInformation
    }

    bottomNav: strapiBottomNav(locale: {eq: "en"}) {
      ...bottomNavContent
    }

    newsItems: allStrapiNewsItem {
      nodes {
        ...newsItem
      }
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "en"}}) {
      nodes {
        ...socialMedia
      }
    }

  }
`